<template>
  <v-container class="sidebar--navigation">
    <v-navigation-drawer class="w-800" v-model="product_detail_dialog" absolute right temporary>
      <v-list-item>
        <v-list-item-content>
          <v-list-item-title>{{ active_product.title }}</v-list-item-title>
        </v-list-item-content>
      </v-list-item>

      <v-divider></v-divider>

      <div class="p-2" v-if="Object.keys(active_product).length">
        <v-row :set="(product_attrs = JSON.parse(active_product.attributes))">
          <v-col cols="6">
            <v-carousel
              style="border: 2px solid orange"
              cycle
              height="400"
              hide-delimiter-background
              show-arrows-on-hover
            >
              <v-carousel-item v-for="(image, i) in getActiveProductImages()" :key="i">
                <v-sheet height="100%" class="p-1">
                  <v-img :src="image" aspect-ratio="1" class="grey lighten-2">
                    <template v-slot:placeholder>
                      <v-row class="fill-height ma-0" align="center" justify="center">
                        <v-progress-circular indeterminate color="grey lighten-5"></v-progress-circular>
                      </v-row>
                    </template>
                  </v-img>
                </v-sheet>
              </v-carousel-item>
            </v-carousel>
          </v-col>
          <v-col cols="6">
            <h3>{{ active_product.title }}</h3>
            <div class="mt-3">
              <p><strong>SKU: </strong> {{ active_product.sku }}</p>
              <p>
                <strong>Price: </strong>
                <span v-if="active_product.variant_id > 0">USD {{ active_product.price }}</span>
                <span v-else><i>In variant level</i></span>
              </p>

              <p>
                <strong>Stock quantity: </strong>
                <span v-if="active_product.variant_id > 0">{{ active_product.stock_quantity }} in stock</span>
                <span v-else
                  >{{ getVariationStock(active_product.variations) }} in stock for
                  {{ active_product.variations.length }} variants</span
                >
              </p>
              <p><strong>Pusblished at:</strong> {{ active_product.published_at }}</p>
              <p>
                <strong>State: </strong>
                <v-chip small v-if="active_product.state == 1" class="ma-2" color="green" text-color="white"
                  >Active</v-chip
                >
                <v-chip small v-else class="ma-2" color="danger" text-color="white">Inactive</v-chip>
              </p>
              <p><strong>Vendor: </strong>{{ active_product.vendor }}</p>
            </div>
          </v-col>
        </v-row>
        <v-row>
          <v-col class="mb-2" cols="12" v-if="active_product.tags">
            <h3 class="mb-2">Tags</h3>
            <v-chip class="mr-2 mb-2" v-for="tag_ in active_product.tags.split(',')"> {{ tag_ }} </v-chip>
          </v-col>

          <v-col cols="12" v-if="active_product.metafields">
            <h3 class="mb-2">Metafields</h3>
            <p v-for="(metafield_, key) in JSON.parse(active_product.metafields)">
              <strong>{{ key }}: </strong> {{ metafield_ }}
            </p>
          </v-col>
        </v-row>
      </div>
    </v-navigation-drawer>
    <v-dialog v-model="variation_dialog" width="900" transition="dialog-bottom-transition">
      <v-card>
        <v-toolbar dark color="primary">
          <v-btn icon dark @click="variation_dialog = false">
            <v-icon style="color: white">{{ icons.mdiClose }}</v-icon>
          </v-btn>
          <v-toolbar-title style="color: white">Variations</v-toolbar-title>
        </v-toolbar>
        <div style="padding: 50px">
          <v-data-table :headers="variation_headers" :items="variations" :items-per-page="10" class="elevation-1 mt-4">
            <template v-slot:body="{ items }">
              <tbody v-if="!loading_variations">
                <tr
                  v-for="variant in items"
                  :key="variant.id"
                  :set="(variant_attributes = JSON.parse(variant.attributes))"
                >
                  <td>
                    <span
                      v-for="(variation_value, key, index) in variant_attributes.variations"
                      :key="`variation-value-${key}-${variant.id}`"
                    >
                      &nbsp; {{ index > 0 ? '-' : '' }} &nbsp; <strong>{{ key }}: </strong>{{ variation_value }}
                    </span>
                  </td>
                  <td>{{ variant.sku }}</td>
                  <td>{{ variant.price }}</td>
                  <td>
                    {{ variant.stock_quantity }}
                  </td>
                </tr>
              </tbody>
              <tbody v-else>
                <tr>
                  <td colspan="6" class="text-center" style="padding: 80px 0px">
                    <img :src="require('@/assets/images/misc/loading.gif')" alt="" width="100px" />
                  </td>
                </tr>
              </tbody>
            </template>
          </v-data-table>
        </div>
      </v-card>
    </v-dialog>

    <v-card class="p-1">
      <v-card-title>
        Shopify Products &nbsp; (Total: {{ products.total }})
        <v-spacer></v-spacer>
        <v-spacer></v-spacer>
        <v-spacer></v-spacer>
        <v-select
          v-model="filters.collection_id"
          style="margin-right: 10px"
          dense
          :items="collections"
          item-text="title_with_products"
          item-value="id"
          label="Filter By Collection"
          @change="fetchShopifyProducts()"
          :disabled="collection_disabled"
          outlined
        >
        </v-select>
        <v-text-field
          v-model="filters.search_keyword"
          :append-icon="icons.mdiMagnify"
          label="Search Keyword"
          outlined
          @change="fetchShopifyProducts()"
          dense
          hide-details
        ></v-text-field>
        <v-btn depressed color="primary" class="ml-1" @click="product_fetch_dialog = true"> Sync Product </v-btn>
      </v-card-title>

      <v-data-table :headers="headers" :items="products.data" disable-pagination hide-default-footer>
        <template v-slot:body="{ items }">
          <tbody v-if="!loading_data">
            <tr v-if="!items.length">
              <td colspan="6" class="text-center" style="padding: 20px 0px">
                <img :src="require('@/assets/images/misc/no-data.png')" alt="" width="250px" />
                <p style="font-size: 25px">No Data Found!!</p>
              </td>
            </tr>
            <tr v-else v-for="item in items" :key="item.id">
              <td>
                <i style="cursor: pointer" @click="showProductDetail(item)" class="primary--text"
                  >#{{ item.shopify_id }}</i
                >
              </td>
              <td style="padding: 4px"><img width="50px" :src="getProductImage(item.attributes)" alt="" /></td>
              <td style="max-width: 300px; font-size: 12px">
                <strong>{{ item.title }}</strong>
              </td>
              <td>{{ item.sku }}</td>
              <td>
                <div v-if="item.variations.length">
                  {{ getVariationStock(item.variations) }} in stock for {{ item.variations.length }} variants
                </div>
                <div v-else>{{ item.stock_quantity }} in stock</div>
              </td>
              <td>
                <v-btn
                  title="Show Variations"
                  fab
                  @click="showVariations(item)"
                  small
                  v-if="item.parent_id == 0 && item.variant_id == 0"
                  class="table-action--button"
                >
                  <v-icon small dark>{{ icons.mdiChevronDown }}</v-icon>
                </v-btn>
              </td>
            </tr>
          </tbody>

          <tbody v-else>
            <tr>
              <td colspan="7" class="text-center" style="padding: 80px 0px">
                <img :src="require('@/assets/images/misc/loading.gif')" alt="" width="100px" />
              </td>
            </tr>
          </tbody>
        </template>
      </v-data-table>
      <v-pagination
        v-model="products.current_page"
        class="my-4"
        @input="handlePageChange"
        :length="products.last_page"
        :total-visible="7"
      ></v-pagination>
    </v-card>

    <v-dialog v-model="product_fetch_dialog" width="500">
      <v-card>
        <v-card-title class="text-h5 grey lighten-2"> Sync Shopify Product </v-card-title>
        <div style="padding: 60px">
          <v-text-field
            v-model="syncable_product"
            label="Enter Shopify ID or Handle"
            placeholder="Enter Shopify ID or Handle"
            outlined
          ></v-text-field>
        </div>
        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="syncShopifyProducts"> Sync Now </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>


<script>
import store from '@/store'
import router from '@/router'
import { mdiChevronDown, mdiClose } from '@mdi/js'

export default {
  props: {
    collection: {
      type: String,
      default: 0,
    },
  },
  data() {
    return {
      loading_data: false,
      shop: null,
      filters: {
        collection_id: null,
        search_keyword: null,
      },
      product_fetch_dialog: false,
      headers: [
        {
          text: 'Remote ID',
          value: 'remote_id',
        },
        {
          text: 'Image',
        },
        {
          text: 'Title',
          value: 'title',
        },
        {
          text: 'SKU',
          value: 'sku',
        },
        {
          text: 'Inventory',
        },

        {
          text: 'Actions',
        },
      ],
      icons: {
        mdiChevronDown,
        mdiClose,
      },
      products: {
        current_page: 1,
        data: [],
        per_page: 20,
      },

      variation_dialog: false,
      loading_variations: false,
      variations: [],
      collections: [],
      variation_headers: [
        {
          text: 'Attributes',
        },
        { text: 'SKU' },
        { text: 'Price' },
        { text: 'Stock' },
      ],

      product_detail_dialog: false,

      active_product: {},

      collection_disabled: false,

      syncable_product: '',
      syncing_product: false,
    }
  },

  async mounted() {
    if (this.collection != 0) {
      this.collection_disabled = true
      this.filters.collection_id = parseInt(this.collection)
    }
    this.shop = store.getters.loggedInShop
    await this.fetchShopifyCollections()

    this.fetchShopifyProducts()
  },

  methods: {
    async fetchShopifyProducts() {
      try {
        this.loading_data = true
        let response = await axios.get(`admin/shops/${this.shop.id}/get-shopify-products`, {
          params: {
            page: this.products.current_page,
            search_keyword: this.filters.search_keyword,
            per_page: this.products.per_page,
            collection_id: this.filters.collection_id,
          },
        })
        if (response.data.success) {
          this.products = response.data.products
          this.is_first = false
        }
      } catch (e) {
        console.log(e)
      } finally {
        this.loading_data = false
      }
    },

    async syncShopifyProducts() {
      try {
        this.loading_data = true
        let response = await axios.get(`http://localhost:8081/index.php?option=com_rapidcart&task=servshopify.processSingleProduct`, {
          params: {
          //   page: this.products.current_page,
          //   search_keyword: this.filters.search_keyword,
          //   per_page: this.products.per_page,
          //   collection_id: this.filters.collection_id,
          },
        })

        dd(response)

        // if (response.data.success) {
        //   this.products = response.data.products
        //   this.is_first = false
        // }
      } catch (e) {
        console.log(e)
      } finally {
        this.loading_data = false
      }
    },

    async fetchShopifyCollections() {
      try {
        this.loading_data = true
        let response = await axios.get(`admin/shops/${this.shop.id}/get-shopify-collections`)
        if (response.data.success) {
          this.collections = response.data.data
        }
      } catch (e) {
      } finally {
        this.loading_data = false
      }
    },
    getProductImage(attributes) {
      attributes = JSON.parse(attributes)
      let img_url = ''
      if (attributes.image_url) {
        if (Array.isArray(attributes.image_url)) {
          if (attributes.image_url[0]) {
            img_url = attributes.image_url[0]
          }
        } else {
          img_url = attributes.image_url
        }
      }
      return img_url
    },

    getVariationStock(variations) {
      let quantity = 0
      variations.forEach(function (variant) {
        quantity += variant.stock_quantity
      })

      return quantity
    },

    async showVariations(product_detail) {
      this.variations = product_detail.variations
      this.variation_dialog = true

      // try {
      //   this.variation_dialog = true
      //   this.loading_variations = true
      //   let response = await axios.get(`admin/shops/${this.shop.id}/shopify-product/${product_id}/variations`)
      //   if (response.data.success) {
      //     this.variations = response.data.variations
      //   }
      // } catch (e) {
      // } finally {
      //   this.loading_variations = false
      // }
    },

    handlePageChange(value) {
      this.fetchShopifyProducts()
    },

    showProductDetail(product) {
        router.push(`/shopify-products/${product.id}`);

      // this.active_product = product
      // this.product_detail_dialog = true
    },

    getActiveProductImages() {
      if (!this.active_product) {
        return
      }

      if (this.active_product.variations && this.active_product.variations.length) {
        let first_variation = this.active_product.variations[0]
        let product_attributes = JSON.parse(first_variation.attributes)

        return product_attributes.image_url
      } else {
        let product_attributes = JSON.parse(this.active_product.attributes)
        return product_attributes.image_url
      }
    },
  },
  watch: {
    product_detail_dialog(value) {
      if (!value) {
        this.active_product = {}
      }
    },
  },
}
</script>